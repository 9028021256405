<template>
    <Authenticator @cancel-profileUpdate="$router.go(-1)"/>
</template>

<script>      
import Authenticator from '@/components/Authenticator/Authenticator'

export default {
  components: {
    Authenticator
  },
}
</script>

<style>

</style>